import { defineMessage } from '@lingui/macro'
import makeUrl from 'components/src/makeUrl'

import { getActiveStoreView, setStoreViews, StoreView } from '@emico/storeviews'

const storeViews: Array<
    StoreView & {
        prismicLocale?: string
    }
> = [
    {
        code: 'pme_en',
        language: 'en',
        locale: 'en-gb',
        name: defineMessage({
            id: 'core.storeConfig.pme_en',
            message: `International`,
        }),
        location: undefined,
        makeUrl: makeUrl(),
        basename: '',
        default: true,
        storeId: 2,
        websiteId: 2,
        prismic: 'pme',
        prismicLocale: 'en-gb',
    },
    {
        code: 'pme_nl',
        language: 'nl',
        locale: 'nl-nl',
        name: defineMessage({
            id: 'core.storeConfig.pme_nl',
            message: `Netherlands`,
        }),
        location: (location) =>
            location.pathname === '/nl' || location.pathname.startsWith('/nl/'),
        makeUrl: makeUrl('nl'),
        basename: '/nl',
        default: false,
        storeId: 3,
        websiteId: 2,
        prismic: 'pme',
        prismicLocale: 'nl-nl',
    },
    {
        code: 'pme_de',
        language: 'de',
        locale: 'de-de',
        name: defineMessage({
            id: 'core.storeConfig.pme_de',
            message: `Germany`,
        }),
        location: (location) =>
            location.pathname === '/de' || location.pathname.startsWith('/de/'),
        makeUrl: makeUrl('de'),
        basename: '/de',
        default: false,
        storeId: 4,
        websiteId: 9,
        prismic: 'pme',
        prismicLocale: 'de-de',
    },
    {
        code: 'pme_be',
        language: 'nl',
        locale: 'nl-be',
        name: defineMessage({
            id: 'core.storeConfig.pme_be',
            message: `Belgium (NL)`,
        }),
        location: (location) =>
            /^\/be(\/(?!fr($|\/))|$)/.test(location.pathname),
        makeUrl: makeUrl('be'),
        basename: '/be',
        default: false,
        storeId: 5,
        websiteId: 3,
        prismic: 'pme',
        prismicLocale: 'nl-be',
    },
    {
        code: 'pme_be_fr',
        language: 'fr',
        locale: 'fr-be',
        name: defineMessage({
            id: 'core.storeConfig.pme_be_fr',
            message: `Belgium (FR)`,
        }),
        location: (location) => /^\/be\/fr(\/|$)/.test(location.pathname),
        makeUrl: makeUrl('be/fr'),
        basename: '/be/fr',
        default: false,
        storeId: 62,
        websiteId: 3,
        prismic: 'pme',
        prismicLocale: 'fr-be',
    },
    {
        code: 'pme_ch',
        language: 'de',
        locale: 'de-ch',
        name: defineMessage({
            id: 'core.storeConfig.pme_ch',
            message: `Switzerland`,
        }),
        location: (location) =>
            location.pathname === '/ch' || location.pathname.startsWith('/ch/'),
        makeUrl: makeUrl('ch'),
        basename: '/ch',
        default: false,
        storeId: 33,
        websiteId: 13,
        prismic: 'pme',
        prismicLocale: 'de-ch',
    },
    {
        code: 'pme_cz',
        language: 'en',
        locale: 'en-cz',
        name: defineMessage({
            id: 'core.storeConfig.pme_cz',
            message: `Czech Republic`,
        }),
        location: (location) =>
            location.pathname === '/cz' || location.pathname.startsWith('/cz/'),
        makeUrl: makeUrl('cz'),
        basename: '/cz',
        default: false,
        storeId: 35,
        websiteId: 14,
        prismic: 'pme',
        prismicLocale: 'cs-cz',
    },
]

const activeStoreView = getActiveStoreView(storeViews, window.location)
setStoreViews(storeViews, activeStoreView)

export default storeViews
