import cx from 'classnames'
import { Badge as BaseBadge } from 'components/src/media/Icon/Badge'
import { ReactNode } from 'react'

import styles from './PmeBadge.module.scss'

const Badge = ({
    badgeClassName,
    children,
}: {
    badgeClassName: string
    children: ReactNode
}) => (
    <BaseBadge
        wrapperClassName={styles.badgeWrapper}
        badgeClassName={cx(badgeClassName, styles.pmeBadge)}
    >
        {children}
    </BaseBadge>
)

export default Badge
