/* eslint-disable @typescript-eslint/no-var-requires */
// List the font files here that should be preloaded for speed.
// Fonts should be woff2 format since that's most supported.

import UniversLTStdBold from './UniversLTStd-Bold.woff2'
import UniversLTStdBoldCn from './UniversLTStd-BoldCn.woff2'
import UniversLTStdLight from './UniversLTStd-Light.woff2'
import UniversLTStd from './UniversLTStd.woff2'

export default [
    UniversLTStd,
    UniversLTStdBold,
    UniversLTStdBoldCn,
    UniversLTStdLight,
]
